<template>
  <div id="users">
    <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>公司签约</el-breadcrumb-item>
    </el-breadcrumb>



  </div>
</template>


<script>
export default {
  name: "companySigning"
}
</script>

<style scoped>

</style>